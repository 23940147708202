<template>
	<div class="container mt-5">
		<v-row>
			<v-col cols="12">
				<v-form :key="sent" ref="formContacto" id="formContacto">
					<v-row>
						<v-col sm="6" cols="12">
							<v-text-field
								v-model="nombre"
								label="Nombre"
								outlined
								hide-details="auto"
								color="primary"
								:rules="[rules.req]"
							/>
						</v-col>
						<v-col sm="6" cols="12">
							<v-text-field
								v-model="apellidos"
								label="Apellidos"
								outlined
								hide-details="auto"
								color="primary"
								:rules="[rules.req]"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col sm="6" cols="12">
							<v-text-field
								v-model="email"
								label="Email"
								outlined
								hide-details="auto"
								color="primary"
								:rules="[rules.req, rules.email]"
							/>
						</v-col>
						<v-col sm="6" cols="12">
							<v-text-field
								v-model="telefono"
								label="Telefono"
								outlined
								hide-details="auto"
								color="primary"
								:rules="[rules.req, rules.phone]"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col class="d-flex justify-content-end" cols="12">
							<v-spacer />
							<v-btn
								color="primary"
								class="rounded-pill"
								@click="$refs.formContacto.validate() ? enviar() : null"
								large
								:loading="loading"
								>Enviar</v-btn
							>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import axios from "axios";
import { req, phone, email } from "@/utils/validations.js";

export default {
	data() {
		return {
			nombre: "",
			apellidos: "",
			email: "",
			telefono: "",

			contactoModal: false,
			sent: 0,
			loading: false,

			rules: {
				req,
				phone,
				email,
			},
		};
	},
	methods: {
		async enviar() {
			if (!this.$refs.formContacto.validate()) return;
			this.loading = true;

			const tokens = await(
				await fetch(
					"https://pruebas.alumbraenergia.es/api/public/autologin.php"
				)
			).json();

			axios({
				method: "POST",
				url: `https://pruebas.alumbraenergia.es/api/public/contactoEfor.php`,
				data: {
					token: tokens.token,
					contact: {
						email: this.email,
						phone: this.telefono,
						firstName: this.nombre,
						lastName: this.apellidos,
					},
					tagId: 3
				},
			}).then((res) => {
				this.$root.$emit(
					"snack",
					"Nuestros agentes se pondrán en contacto contigo lo antes posible"
				);
				this.nombre = "";
				this.apellidos = "";
				this.email = "";
				this.telefono = "";
				this.loading = false;
				this.sent++;
			});
		},
	},
};
</script>

<style>
</style>